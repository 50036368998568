import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Tooltip,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";

function VersionTable({ versions }) {
  const [search, setSearch] = useState("");
  const filteredVersions = versions
    .filter((row) => row.version.includes(search) || row.url.includes(search))
    .filter((row) => row.arch == "amd64 + arm64");

  function extractDate(timestamp) {
    const date = new Date(timestamp);
    // const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    // const day = String(date.getDate()).padStart(2, "0");
    // diff in days from today
    const diff = Math.floor((new Date() - date) / (1000 * 60 * 60 * 24));
    if (diff === 0) {
      return "Today";
    }
    if (diff === 1) {
      return "Yesterday";
    }

    return `${diff} days ago`;
  }

  function bytesToMB(bytes) {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2); // Round to 2 decimal places
  }

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
  };

  return (
    <Paper sx={{ padding: 3, mb: 4, boxShadow: "none" }}>
      <TextField
        variant="outlined"
        placeholder="Search Versions"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          marginBottom: 3,
          borderRadius: 2,
          "& .MuiOutlinedInput-root": {
            borderRadius: "16px", // Sets the input field border radius to 16px
            fontSize: (theme) => theme.typography.body2.fontSize, // Use body2 font size
          },
        }}
      />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F0F2F5!important",
                  borderTopLeftRadius: 4,
                  borderBottomLeftRadius: 4,
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0">
                  <b>Version</b>
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F0F2F5!important",
                  fontWeight: 600,
                  pb: 1.5,
                  pt: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0">
                  <b>Pull URL</b>
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F0F2F5!important",
                  fontWeight: 600,
                  pb: 1.5,
                  pt: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0">
                  <b>Size</b>
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F0F2F5!important",
                  fontWeight: 600,
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  pb: 1.5,
                  pt: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0">
                  <b>Last Updated</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVersions.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{
                    border: "none",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="#4FD1C5"
                    sx={{ fontWeight: 500 }}
                  >
                    {row.version}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Tooltip title="Copy URL">
                      <IconButton
                        size="small"
                        onClick={() => handleCopy(row.url)}
                      >
                        <ContentCopyIcon
                          fontSize="small"
                          style={{ color: "#4FD1C5" }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={row.url}>
                      <Typography
                        variant="body1"
                        noWrap
                        sx={{
                          maxWidth: 300,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                        }}
                      >
                        {row.url}
                      </Typography>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {bytesToMB(row.size)} Mb
                  </Typography>
                  <Typography variant="caption" color="#A0AEC0">
                    {row.platforms}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {extractDate(row.updatedAt)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default VersionTable;
