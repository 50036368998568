import React from "react";
import { Chip } from "@mui/material";
const severityMap = {
  Negligible: { color: "#34C759", backgroundColor: "#34C7591A" },
  Critical: { color: "#FFF", backgroundColor: "#FF3B30" },
  Medium: { color: "#FF9500", backgroundColor: "#FF95001A" },
  Unknown: { color: "#A0AEC0", backgroundColor: "#A0AEC01A" },
  High: { color: "#FF3B30", backgroundColor: "FF3B301A" },
  Low: { color: "#FFCC00", backgroundColor: "#FFCC001A" },
};

function StatusChips({ severity }) {
  const { color, backgroundColor } = severityMap[severity] || {};

  return (
    <Chip
      label={severity}
      sx={{
        backgroundColor: backgroundColor,
        color: color,
        fontWeight: "600",
      }}
    />
  );
}

export default StatusChips;
