// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Images from "./pages/images";
import ImageDetails from "./pages/imageDetails";
// import scss
import "../src/assets/styles/main.scss";
import Website from "./pages/website";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Website />} />
        <Route path="/images" element={<Images />} />
        <Route path="/image-details/:imageId" element={<ImageDetails />} />
      </Routes>
    </Router>
  );
};

export default App;
