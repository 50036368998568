import React from "react";
import Iframe from "react-iframe";

const Website = () => {
  return (
    <>
      <Iframe
        url="https://mindful-place-637257.framer.app/"
        width="100%"
        height="100%"
        id="home-page"
        className=""
        display="block"
        position="absolute"
        styles={{
          "border-width": "0px 0px 0px 0px",
        }}
      />
    </>
  );
};

export default Website;
