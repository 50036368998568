import React from "react";
import { Box, Typography } from "@mui/material";
import nodejs from "../../assets/images/imageIcons/nodejs.png";
import php from "../../assets/images/imageIcons/php.png";
import postgres from "../../assets/images/imageIcons/postgres.png";
import python3 from "../../assets/images/imageIcons/python.png";

const getImagePath = (name) => {
  const serviceName = name.split("/")[0];

  const imageMap = {
    nodejs: nodejs,
    php: php,
    postgres: postgres,
    python3: python3,
  };

  return imageMap[serviceName];
};

const extractName = (name) => {
  const parts = name.split("/");
  if (parts.length < 2) {
    console.error("Name format is incorrect");
    return null; // or handle as appropriate for your app
  }

  const temp = parts[1];
  const tempParts = temp.split(":");

  return tempParts[0];
};

function Header({ name, versions }) {
  console.log(versions);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: "white",
        p: 3,
        borderRadius: 4,
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <Box
          display="flex"
          sx={{
            alignItems: "center",
            gap: 2,
          }}
        >
          <img src={getImagePath(name)} alt="nodejs" style={{ height: 50 }} />
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {extractName(name)}
          </Typography>
          {/* <StatusChips /> */}
        </Box>
      </Box>
      <Box display="flex" gap={6}>
        <Box>
          <Typography variant="body2" color="textSecondary" sx={{}}>
            Last Updated
          </Typography>
          <Typography variant="h6">3 hours ago</Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary">
            Latest Version
          </Typography>
          <Typography variant="h6">
            {extractName(name) == "nodejs" ? "22" : ""}
            {extractName(name) == "php" ? "8.2" : ""}
            {extractName(name) == "postgres" ? "15" : ""}
            {extractName(name) == "python3" ? "3.11" : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
