import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ImageCard from "./imageCard";
import axios from "axios";

const ImageGrid = () => {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/data/imageGroups/")
      .then((response) => {
        setImageData(response.data["imageGroups"]);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        p: 3,
      }}
    >
      {imageData.map((image, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ImageCard {...image} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageGrid;
