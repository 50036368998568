import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
} from "@mui/material";
import { ReactComponent as FolderIcon } from "../../assets/images/images.svg";
// import { ReactComponent as PackageIcon } from "../../assets/images/packages.svg";
// import { ReactComponent as RequestIcon } from "../../assets/images/requests.svg";
import { ReactComponent as DocsIcon } from "../../assets/images/Vector.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

function Sidebar() {
  const [activeItem, setActiveItem] = useState("Images");
  const [hoveredItem, setHoveredItem] = useState(null);

  const menuItems = [
    { text: "Images", key: "Images", icon: FolderIcon },
    // { text: "Packages", key: "Packages", icon: PackageIcon },
    // { text: "Requests", key: "Requests", icon: RequestIcon },
    { text: "Docs", key: "Docs", icon: DocsIcon },
  ];

  return (
    <Box
      sx={{
        minWidth: 250,
        backgroundColor: "#F8F9FA",
        padding: 0,
        background: "white",
        ml: 4,
        mt: "20px",
        borderRadius: 4,
        mb: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Logo Section */}
      <Typography
        variant="h6"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          m: "auto",
          mt: 6,
          color: "black",
          mb: 6,
          gap: 1,
        }}
      >
        <Logo />
        <div>
          <b>POD</b>ARMOR
        </div>
      </Typography>

      <List>
        {menuItems.map((item) => {
          const IconComponent = item.icon;
          const isActive = activeItem === item.key;
          const isHovered = hoveredItem === item.key;

          return (
            <ListItem key={item.key} disablePadding sx={{ mb: 1 }}>
              <ListItemButton
                onClick={() => setActiveItem(item.key)}
                onMouseEnter={() => setHoveredItem(item.key)}
                onMouseLeave={() => setHoveredItem(null)}
                sx={{
                  position: "relative",
                  padding: "10px 40px", // Adjusting horizontal padding
                  color: isActive ? "#38B2AC" : "#A0AEC0",
                  backgroundColor: isActive ? "#E6FFFA" : "transparent",
                  "&:hover": {
                    backgroundColor: "#FFF",
                    color: "#38B2AC",
                  },
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "100%",
                    width: "4px",
                    backgroundColor: isActive ? "#38B2AC" : "transparent",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: isActive || isHovered ? "#38B2AC" : "#A0AEC0",
                    minWidth: "auto",
                    marginRight: 2,
                  }}
                >
                  <IconComponent
                    style={{
                      stroke: isActive || isHovered ? "#4FD1C5" : "#A0AEC0",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{ fontWeight: 600 }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <Box
        sx={{
          padding: "10px 20px",
          mt: "auto",
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
        }}
      >
        <ListItemButton
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center content within the button
            width: "100%",
          }}
        >
          <ListItemIcon sx={{ minWidth: "auto" }}>
            <Avatar alt="Meet Shah" src="/path/to/avatar.jpg" />
          </ListItemIcon>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            <ListItemText
              primary="Meet Shah"
              primaryTypographyProps={{ fontWeight: 500 }}
              sx={{
                textAlign: "center",
                fontSize: (theme) => theme.typography.body2.fontSize,
              }}
            />
            <Box sx={{ fontWeight: 500, ml: 1 }}>&gt;</Box>
          </Box>
        </ListItemButton>
      </Box>
    </Box>
  );
}

export default Sidebar;
