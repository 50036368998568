import React, { useState, useEffect } from "react"; // Import useEffect
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { saveAs } from "file-saver";
import download from "../../assets/images/download.svg";
import Version from "../../assets/images/version_tag.svg";

function SBOM({ versions }) {
  const [search, setSearch] = useState("");
  const [selectedVersion, setSelectedVersion] = useState(
    versions.filter((row) => row.arch == "amd64 + arm64")[0].version,
  );
  const [selectedArchitecture, setSelectedArchitecture] = useState(
    versions.filter((row) => row.arch == "amd64 + arm64")[0].arch,
  );
  const [filteredVersions, setFilteredVersions] = useState([]); // Initialize as empty array
  const [displayedVersions, setDisplayedVersions] = useState([]); // New state for displayed versions

  // Effect to filter sbom based on selected version and architecture
  useEffect(() => {
    const selectedVersionData = versions.find(
      (v) => v.version === selectedVersion && v.arch === selectedArchitecture,
    );
    if (selectedVersionData && Array.isArray(selectedVersionData.sbom)) {
      setFilteredVersions(selectedVersionData.sbom);
    } else {
      setFilteredVersions([]); // Reset to empty array if no data found
    }
  }, [selectedVersion, selectedArchitecture, versions]);

  // Effect to filter displayed versions based on search input
  useEffect(() => {
    if (search.trim() === "") {
      setDisplayedVersions(filteredVersions);
    } else {
      const lowercasedSearch = search.toLowerCase();
      setDisplayedVersions(
        filteredVersions.filter((row) =>
          row.name.toLowerCase().includes(lowercasedSearch),
        ),
      );
    }
  }, [search, filteredVersions]);

  const handleDownloadCSV = () => {
    const csvHeader = "Package,Version,Repository,License,Architecture\n";
    const csvRows = displayedVersions.map(
      (row) =>
        `${row.name},${row.version},${row.repository || "N/A"},${row.license || "N/A"},${selectedArchitecture}`,
    );
    const csvContent = csvHeader + csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "SBOM.csv");
  };

  return (
    <Paper sx={{ padding: 3, mb: 4, boxShadow: "none" }}>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
          <Select
            renderValue={(value) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={Version}
                  alt="version"
                  style={{ marginRight: "0.5rem" }}
                />
                <span style={{ color: "#A0AEC0" }}>Version:</span>
                <span style={{ color: "black", marginLeft: "0.3rem" }}>
                  {value}
                </span>
              </div>
            )}
            onChange={(e) => setSelectedVersion(e.target.value)}
            displayEmpty
            value={selectedVersion}
            variant="outlined"
            sx={{ borderRadius: 2, pr: 3 }}
          >
            {[
              ...new Set(
                versions
                  .filter((row) => row.arch == "amd64 + arm64")
                  .map((v) => v.version),
              ),
            ].map((version) => (
              <MenuItem key={version} value={version}>
                {version}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={selectedArchitecture}
            onChange={(e) => setSelectedArchitecture(e.target.value)}
            displayEmpty
            variant="outlined"
            sx={{ borderRadius: 2, pr: 2 }}
          >
            {[
              ...new Set(
                versions
                  .filter((row) => row.arch == "amd64 + arm64")
                  .map((v) => v.arch),
              ),
            ].map((arch) => (
              <MenuItem key={arch} value={arch}>
                {arch}
              </MenuItem>
            ))}
          </Select>
          <TextField
            variant="outlined"
            placeholder="Search Versions"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "16px",
              },
            }}
          />
        </div>
        <Button
          variant="contained"
          onClick={handleDownloadCSV}
          sx={{
            backgroundColor: "#4FD1C5",
            color: "white",
            fontWeight: 600,
            marginBottom: 2,
            boxShadow: 0,
          }}
        >
          <img
            src={download}
            alt="download"
            style={{ marginRight: "0.5rem" }}
          />
          DOWNLOAD SBOM
        </Button>
      </div>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F0F2F5!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0">
                  <b>Package</b>
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F0F2F5!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0">
                  <b>Version</b>
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F0F2F5!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0">
                  <b>Repository</b>
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F0F2F5!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0">
                  <b>License</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedVersions.length > 0 ? ( // Check if displayedVersions has data
              displayedVersions.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {row.version}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {row.repository ||
                        (row.type == "deb" ? "Debian" : "NodeJS")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {row.license || "MIT"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body1">No data available</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default SBOM;
