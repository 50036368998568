import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function BreadcrumbNav({ onBack }) {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <IconButton onClick={onBack} sx={{ color: "#4A5568" }}>
        <ArrowBackIcon />
      </IconButton>
      <Typography
        onClick={onBack}
        variant="body2"
        color="textSecondary"
        sx={{ mx: 1, cursor: "pointer" }}
      >
        Image Repository
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mx: 0.5 }}>
        /
      </Typography>
      <Typography variant="body2" color="textPrimary">
        Node JS
      </Typography>
    </Box>
  );
}

export default BreadcrumbNav;
